import React, { useState } from "react"
import { Box, Flex, Text, Container } from "theme-ui"
import { motion } from "framer-motion"
import { useStore } from "../../store"
import Parser from "html-react-parser"
import styled from "@emotion/styled"

//components
import useMenuItems from "../../hooks/useMenuItems"
import Link from "../Link"
import ChevronDown from "../../icons/chevron-down.svg"
import theme from "../../theme"

interface MenuItem {
  id: string
  label?: string // the "?" makes this optional
  url?: string
  childItems?: {
    nodes?: MenuItem[] // array of MenuItem, can also be written as Array<MenuItem>
  }
}

interface Props {
  path?: string
}

const DesktopMenu = (props: Props) => {
  const { path } = props

  const [activeMenuIndex, setActiveMenuIndex] = useState(null)

  const items = useMenuItems({ slug: "menu" })

  const handleOpen = (itemIndex: number, hasSubMenu?: boolean) => {
    if (!hasSubMenu) {
      setActiveMenuIndex(null)
      return
    }

    if (itemIndex === activeMenuIndex) {
      setActiveMenuIndex(null)
    } else {
      setActiveMenuIndex(itemIndex)
    }
  }

  const handleClose = () => {
    setActiveMenuIndex(null)
  }

  return (
    <Container
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
      }}
    >
      <Flex sx={{ pr: "200px", justifyContent: "flex-end" }}>
        <Flex
          sx={{
            alignItems: "center",
            "@media (max-width: 1300px)": {
              display: "none",
            },
          }}
        >
          {items?.map((item: any, index: number) => (
            <Box
              key={index}
              sx={{
                "&:hover": {
                  color: "primary",
                  "div > *": {
                    color: "primary",
                  },
                },
              }}
            >
              <MenuButton
                item={item}
                active={index === activeMenuIndex}
                onOpen={() =>
                  handleOpen(index, !!(item?.childItems?.nodes?.length > 0))
                }
                onClose={handleClose}
                path={path}
              />
            </Box>
          ))}
        </Flex>
      </Flex>
    </Container>
  )
}

const ProductCard = styled(Link)`
  display: inline-block;
  padding-bottom: 40px;
  &:hover {
    .sublink-text {
      color: ${theme.colors.primary};
    }
  }
`
export default DesktopMenu

interface MenuButtonProps {
  active?: boolean
  item?: MenuItem
  path?: string
  onOpen?: () => void // this is how to define a void function type
  onClose?: () => void
}

const MenuButton = (props: MenuButtonProps) => {
  const {
    item: { label, childItems, url },
    path,
  } = props

  const linkProps =
    childItems?.nodes?.length === 0
      ? {
          as: Link, // make Button function as a Link if no children
          to: url,
          activeStyle: {
            "&:before": {
              opacity: 1,
            },
          },
        }
      : {
          as: Link, // make Button function as a Link if no children
          to: url,
          activeStyle: {
            "&:before": {
              opacity: 1,
            },
          },
        }

  return (
    <Box
      sx={{
        ".dropdown:hover": {
          ".dropdown-menu": { display: "block" },
        },
      }}
    >
      <Box
        {...linkProps}
        className="dropdown"
        ml="30px"
        variant="text.desktopNav"
        sx={{
          height: theme.headerHeight,
          whiteSpace: "nowrap",
          cursor: "pointer",
          display: "flex",
          position: "relative",
          flexDirection: "row",
          alignItems: "center",
          ".chevron-icon": {
            color:
              path?.replace(/^[\\/]+|[\\/]+$/g, "") ===
              url?.replace(/^[\\/]+|[\\/]+$/g, "")
                ? "primary"
                : "white",
          },
          ".link-text ": {
            color:
              path?.replace(/^[\\/]+|[\\/]+$/g, "") ===
              url?.replace(/^[\\/]+|[\\/]+$/g, "")
                ? "primary"
                : "white",
          },
          "&:hover": {
            div: {
              color: "primary",
            },
            svg: {
              path: {
                fill: "primary",
              },
            },
          },
        }}
      >
        <Box className={"link-text"}>{label}</Box>
        {childItems?.nodes?.length > 0 && (
          <ChevronDown className="chevron-icon" />
        )}
        {childItems?.nodes?.length > 0 && (
          <SubMenu path={path} items={childItems?.nodes} />
        )}
      </Box>
    </Box>
  )
}

const SubMenu = (props: {
  items?: MenuItem[]
  path?: string
  onClose?: () => void
}) => {
  // the types can also be defined ^here^ instead of creating an interface
  const { items, onClose, path } = props

  return (
    <Box className="dropdown-menu" sx={{ display: "none" }}>
      <Box // click away listener
        sx={{
          zIndex: -1,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
        }}
      />

      <motion.div
        animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        style={{
          zIndex: -1,
          display: "flex",
          padding: "30px 0 0 20px",
          background: theme.colors.blueModuleBg,
          position: "absolute",
          flexDirection: "column",
          left: 0,
          top: theme.headerHeight,
          maxHeight: "calc(100vh - 94px)",
          width: "200px",
          overflow: "auto",
        }}
      >
        {items &&
          items.map((item: MenuItem, index: number) => (
            <ProductCard
              key={index}
              to={item.url}
              onClick={onClose}
              aria-label={item.label}
              label={item.label}
            >
              <Text
                className="sublink-text"
                variant="text.desktopNav"
                sx={{
                  color:
                    path?.replace(/^[\\/]+|[\\/]+$/g, "") ===
                    item.url?.replace(/^[\\/]+|[\\/]+$/g, "")
                      ? "primary !important"
                      : "white",
                  "&:hover": {
                    color: "primary",
                  },
                }}
              >
                {Parser(item.label)}
              </Text>
            </ProductCard>
          ))}
      </motion.div>
    </Box>
  )
}
