import React from "react"
import styled from "@emotion/styled"
import { IconButton } from "theme-ui"

// import app components
import useThemeOptions from "../hooks/useThemeOptions"
import FacebookIcon from "../icons/facebook.svg"
import TwitterIcon from "../icons/twitter.svg"
import InstagramIcon from "../icons/instagram.svg"
import LinkedIn from "../icons/linkedin.svg"
import Link from "../components/Link"
import theme from "../theme"

const Socials: React.FC<Props> = (props: Props) => {
  const {
    color,
    footer: { instagram, facebook, linkedin, twitter },
  } = useThemeOptions()

  return (
    <Container>
      {facebook && (
        <LinkButton
          color={color}
          to={facebook}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Facebook"
        >
          <IconButton
            sx={{
              "&:hover": {
                cursor: "pointer",
                svg: {
                  path: {
                    fill: "primary",
                  },
                },
              },
            }}
          >
            <FacebookIcon />
          </IconButton>
        </LinkButton>
      )}

      {instagram && (
        <LinkButton
          color={color}
          to={instagram}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Linkedin"
        >
          <IconButton
            sx={{
              "&:hover": {
                cursor: "pointer",
                svg: {
                  path: {
                    fill: "primary",
                  },
                },
              },
            }}
          >
            <InstagramIcon />
          </IconButton>
        </LinkButton>
      )}

      {linkedin && (
        <LinkButton
          color={color}
          to={linkedin}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Linkedin"
        >
          <IconButton
            sx={{
              "&:hover": {
                cursor: "pointer",
                svg: {
                  path: {
                    fill: "primary",
                  },
                },
              },
            }}
          >
            <LinkedIn />
          </IconButton>
        </LinkButton>
      )}

      {twitter && (
        <LinkButton
          color={color}
          to={twitter}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Twitter"
        >
          <IconButton>
            <TwitterIcon />
          </IconButton>
        </LinkButton>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  transform: translateX(-10px);
  &:hover {
    cursor: pointer;
  }
`

const LinkButton = styled(Link)`
  text-decoration: none;
  display: inline-block;
  margin: 0 5px;
  &:hover {
    cursor: pointer;
    svg {
      path {
        fill: ${theme.colors.primary};
      }
    }
  }
  svg {
    path {
      fill: ${(props) => props.color};
    }
    &:hover {
      cursor: pointer;
    }
  }
`

export default Socials
