import React from "react"
import Helmet from "react-helmet"
import { ApolloProvider } from "@apollo/react-hooks"

// import polyfills
import "core-js/es6/number"
import "core-js/es6/array"

// import external css
import "minireset.css"

// import app components
import PageWrapper from "./PageWrapper"
import { StoreProvider } from "../store"
import client from "../apollo"
import Seo from "./Seo"

const Layout = (props) => {
  const { seo, pageContext } = props

  return (
    <ApolloProvider client={client}>
      <StoreProvider>
        <Helmet>
          <html lang="en" />
          <meta name="description" />
          <link rel="stylesheet" href="https://use.typekit.net/wqj7xsr.css" />
        </Helmet>

        <Seo {...pageContext} />

        {seo && <Seo seo={seo} />}

        <PageWrapper {...props}>{props?.children}</PageWrapper>
      </StoreProvider>
    </ApolloProvider>
  )
}

export default Layout
