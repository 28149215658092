import React from "react"
import { Container, Box } from "theme-ui"

// import app components
import theme from "../theme"
import Header from "./Header"
import Footer from "./Footer"
import LightBox from "./lightBox"
import { LoginDialog } from "../auth/components"
import { useAuthServices } from "../auth"
import Modal from "./Modal"
import { Theater } from "mdi-material-ui"

const Layout = (props) => {
  useAuthServices(props?.location?.pathname)
  {
  }
  return (
    <>
      <Container
        sx={{
          justifyContent: "center",
          position: "absolute",
          "@media (max-width: 40em)": { top: 90 },
          top: `${props.isHomePage ? 0 : "100px"}`,
          left: 0,
        }}
      >
        <Header {...props} />
        <Box as="main" {...props}>
          {props?.children}
        </Box>
        <Footer {...props} />
      </Container>
      <LightBox />
      <LoginDialog />
      <Modal {...props} />
    </>
  )
}

export default Layout
