import React from "react"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import { Button as ThemeButton, Spinner, Box } from "theme-ui"

// import app components
import { formatLink } from "../utils"
import HalfArrow from "../icons/half-arrow.svg"

const Button = ({
  children,
  to,
  loading,
  disabled,
  customeVariant,
  ...rest
}) => {
  // Fixes bug where front page url ('/') is returning WP url
  const formattedLink = formatLink(to)

  const element = (
    <ThemeButton
      {...rest}
      loading={loading ? 1 : 0}
      sx={{
        display: "flex",
        variant: `${customeVariant ? customeVariant : "buttons.primary"}`,
        position: "relative",
        cursor: "pointer",
        textAlign: "center",
        px: [
          `${customeVariant ? 0 : "20px"}`,
          `${customeVariant ? 0 : "35px"}`,
        ],
        py: "8px",
        borderRadius: "40px",
        ".btn-loader": {
          position: "absolute",
          opacity: 0,
          zIndex: 1,
          pointerEvents: "none",
          left: "calc(50% - 12.5px)",
        },
        ".btn-children": {
          opacity: loading && 0,
          textAlign: "center",
        },
        " .btn-loader ": {
          opacity: loading && 1,
        },
      }}
      disabled={disabled}
    >
      <Spinner className="btn-loader" size={25} />
      <span className="btn-children">{Parser(children)}</span>
      <Box
        sx={{
          mt: "auto",
          ml: ["10px", "20px"],
          svg: {
            width: customeVariant === "buttons.products" ? "75%" : "null",
          },
          display:
            customeVariant === "buttons.secondary" ||
            customeVariant === "buttons.products" ||
            customeVariant === "buttons.formButton"
              ? "null"
              : "none",
        }}
      >
        <HalfArrow />
      </Box>
    </ThemeButton>
  )

  if (to) {
    if (disabled) {
      return element
    } else if (formattedLink.includes("http")) {
      return (
        <a href={formattedLink} target="_blank" rel="noreferrer">
          {element}
        </a>
      )
    } else if (
      formattedLink.includes("tel:") ||
      formattedLink.includes("mailto:")
    ) {
      return <a href={formattedLink}>{element}</a>
    } else {
      return <Link to={formattedLink}>{element}</Link>
    }
  } else {
    return element
  }
}

export default Button
