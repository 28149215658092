import { useStaticQuery, graphql } from "gatsby"

export const useThemeOptions = () => {
  const {
    wp: {
      themeOptions: { themeOptions },
    },
  } = useStaticQuery(graphql`
    query {
      wp {
        themeOptions {
          themeOptions {
            fieldGroupName
            footer {
              fieldGroupName
              facebook
              instagram
              linkedin
              twitter
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
              footerTitle
              locations {
                title
                address
                phone
              }
              serviceArea {
                name
              }
              contactLink {
                target
                title
                url
              }
            }
            categories {
              fieldGroupName
              items {
                fieldGroupName
                alignimage
                backgroundcolor
                id
                text
                headline
                subHeadline
                link {
                  url
                  title
                  target
                }
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
              }
            }
            header {
              fieldGroupName
              items {
                fieldGroupName
                alignimage
                backgroundcolor
                id
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                  link
                }
              }
            }
          }
        }
      }
    }
  `)

  return themeOptions
}

export default useThemeOptions
