import React, { useEffect } from "react"
import { Link } from "gatsby"
import HamburgerMenu from "react-hamburger-menu"
import { Container, Box, Grid } from "theme-ui"
import useScroll from "@react-hooks-custom/use-scroll"

// import app components
import DesktopMenu from "./menu/DesktopMenu"
import MobileMenu from "./menu/MobileMenu"
import { useStore } from "../store"
import Logo from "../icons/polar-header.svg"

const Header = (props) => {
  const { path } = props

  const [
    {
      appState: { menu: menuActive },
      userState: { isLoggedIn, user },
    },
    dispatch,
  ] = useStore()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path, dispatch])

  const { scrollX, scrollY } = useScroll()

  return (
    <>
      <Container
        sx={{
          "@media (max-width: 40em)": {
            height: 90,
            boxShadow: scrollY > 0 ? "0 0 10px #012040" : null,
          },
          height: `${({ theme: { headerHeight } }) => headerHeight}px`,
          position: "fixed",
          left: 0,
          top: 0,
          width: ["100%", "100%", "100%"],
          zIndex: "999",
          bg: "blueModuleBg",
          "@media (min-width: 1300px)": {
            transition: "background-color 0.3s ease-out",
            bg: `${
              props?.isHomePage && scrollX >= scrollY
                ? "transparent"
                : "blueModuleBg"
            }`,
          },
        }}
      >
        <Grid
          sx={{
            postion: "relative",
            height: `${({ theme: { headerHeight } }) => headerHeight}px`,
            zIndex: 9,
            gridRow: 1,
            gap: 0,
          }}
          columns={[1, "20% 80%", "18% 82%"]}
        >
          <Box
            sx={{
              position: "relative",
              zIndex: 11,
              textDecoration: "none",
              gridColumnStart: 1,
              gridColumnEnd: 2,
              gridRowStart: 1,
              maxHeight: ["90px", "100px"],
              display: "flex",
              alignItems: "center",
              justifyContent: ["flex-start", "center"],
            }}
          >
            <Link to="/">
              <Box
                sx={{
                  display: "flex",
                  bg: "white",
                  height: [90, 155],
                  width: [90, 135],
                  boxShadow: [null, "0px 3px 6px #00000029"],
                  alignItems: "center",
                  justifyContent: "center",
                  svg: {
                    pt: [null, 25],
                    my: "auto",
                    height: [75, 110],
                    width: [75, 116],
                  },
                }}
              >
                <Logo />
              </Box>
            </Link>
          </Box>

          <Box
            sx={{
              position: "relative",
              zIndex: 2,
              gridColumnStart: 2,
              gridColumnEnd: 3,
              gridRowStart: 1,
              maxHeight: `${({ theme: { headerHeight } }) => headerHeight}px`,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <DesktopMenu path={path} />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                transform: "translateX(15px)",
                cursor: "pointer",
                "@media (min-width: 1300px)": { display: "none" },
              }}
              p={40}
              onClick={() => dispatch({ type: "TOGGLE_MENU" })}
            >
              <HamburgerMenu
                color="#fff"
                isOpen={menuActive}
                width={26}
                height={15}
                strokeWidth={2}
                menuClicked={() => ""}
              />
            </Box>
            <MobileMenu path={path} />
          </Box>
        </Grid>
      </Container>
      <MobileMenu path={path} />
    </>
  )
}

export default Header
