import { graphql, useStaticQuery } from "gatsby"

interface UseMenuItemsProps {
  location?: string
  slug?: string
}

const useMenuItems = ({ location, slug }: UseMenuItemsProps) => {
  if (!location && !slug) {
    console.warn(`useMenuItems requires either a slug or location parameter`)
    console.warn(
      `useMenuItems example slug: "main-menu", example location: "GATSBY_HEADER_MENU"`
    )
    return null
  }

  const {
    allWpMenu: { nodes },
  } = useStaticQuery(graphql`
    {
      allWpMenu {
        nodes {
          id
          slug
          locations
          menuItems {
            nodes {
              id
              url
              label
              target
              parentDatabaseId
              cssClasses

              connectedNode {
                node {
                  ... on WpContentNode {
                    uri
                  }
                }
              }

              childItems {
                nodes {
                  id
                  label
                  url
                  cssClasses
                  connectedNode {
                    node {
                      ... on WpContentNode {
                        uri
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  if (location) {
    const menu =
      nodes && nodes.find((node: any) => node.locations.includes(location))

    return (
      menu?.menuItems?.nodes &&
      menu.menuItems.nodes.filter((item) => item.parentDatabaseId === 0)
    )
  } else if (slug) {
    const menu = nodes.find((node: any) => node.slug === slug)

    return (
      menu?.menuItems?.nodes &&
      menu.menuItems.nodes.filter((item) => item.parentDatabaseId === 0)
    )
  }
}

export default useMenuItems
