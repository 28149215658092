import React from "react"
import {
  Container,
  Box,
  Grid,
  Heading,
  Text,
  Flex,
  Link as ThemeLink,
  Divider,
} from "theme-ui"
import { Link as GatsbyLink } from "gatsby"

// import app components
import useThemeOptions from "../hooks/useThemeOptions"
import Link from "./Link"
import PinLogo from "../icons/pin-logo.svg"
// import LogoArrow from "../icons/arrowButton.svg"
import BackLogo from "../icons/bearclaw.svg"
import Edges from "./Edges"
import Socials from "./Socials"
import BackgroundImage from "./BackgroundImage"
import Parser from "html-react-parser"
import Button from "./Button"

const Footer = () => {
  const {
    footer: { contactLink, image, footerTitle, serviceArea, locations },
  } = useThemeOptions()

  return (
    <Container>
      <Box
        sx={{
          py: "60px",
          position: "relative",
          zIndex: 0,
          background:
            "transparent linear-gradient(112deg, #073A70 0%, #03213F 100%)",
        }}
      >
        <Box
          sx={{
            right: 0,
            position: "absolute",
            bottom: 0,
            zIndex: 1,
          }}
        >
          <BackLogo />
        </Box>
        <Edges size="lg">
          <Flex
            sx={{
              flexDirection: ["column", "row"],
              justifyContent: ["center", "space-between"],
              alignItems: "center",
              flexWrap: "wrap",
              position: "relative",
              zIndex: 2,
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mx: ["auto", "unset"],
              }}
            >
              <Heading
                variant="text.footerHeading"
                sx={{
                  display: ["block", "inline-block"],
                  color: "white",
                  pb: 2,
                }}
              >
                {Parser(footerTitle)}
              </Heading>
              {contactLink?.url && (
                <Button to={contactLink.url}>
                  {Parser(contactLink.title)}
                </Button>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "auto",
                width: ["100%", "auto"],
              }}
            >
              <Heading
                variant="text.footerHeading"
                sx={{
                  display: ["block", "inline-block"],
                  color: "white",
                  mb: "30px",
                }}
              >
                LOCATIONS
              </Heading>
              {locations &&
                locations.length > 0 &&
                locations.map((location: any, idx: number) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: "30px",
                    }}
                  >
                    {location.title && (
                      <Heading
                        sx={{ color: "white" }}
                        variant="text.footerLocations"
                        key={idx}
                      >
                        {Parser(location.title)}
                      </Heading>
                    )}
                    {location.address && (
                      <Heading
                        sx={{ color: "white" }}
                        variant="text.footerLocations"
                        key={idx}
                      >
                        {Parser(location.address)}
                      </Heading>
                    )}
                    {location.phone && (
                      <ThemeLink
                        variant="text.footerLocations"
                        children={Parser(location.phone)}
                        sx={{
                          color: "white",
                          textDecoration: "none",
                          "&:hover": { color: "primary" },
                          maxWidth: 200,
                        }}
                        href={`tel:${Parser(
                          location.phone.replace(/\./g, "")
                        )}`}
                      />
                    )}
                    <Divider sx={{ color: "white", width: "60%" }} />
                  </Box>
                ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "auto",
                width: ["100%", "auto"],
              }}
            >
              <Heading
                variant="text.footerHeading"
                sx={{
                  display: ["block", "inline-block"],
                  color: "white",
                  pr: 2,
                  mb: "30px",
                }}
              >
                SERVICE AREA
              </Heading>
              {serviceArea &&
                serviceArea.length > 0 &&
                serviceArea.map((area: any, areaIndex: number) => (
                  <Box
                    key={areaIndex}
                    sx={{
                      display: "flex",
                      marginBottom: "15px",
                    }}
                  >
                    <PinLogo />
                    {area.name && (
                      <Heading
                        sx={{ color: "white", pl: "10px" }}
                        variant="text.footerLocations"
                      >
                        {Parser(area.name)}
                      </Heading>
                    )}
                  </Box>
                ))}

              <Box sx={{ width: "100%", mt: "20px" }}>
                <Heading
                  variant="text.footerHeading"
                  sx={{
                    display: ["block", "inline-block"],
                    color: "white",
                    mb: "15px",
                  }}
                >
                  SOCIAL
                </Heading>
                <Socials
                  color="white"
                  // socials={{ instagram, facebook, linkedin, twitter }}
                />
              </Box>
            </Box>
          </Flex>
        </Edges>
      </Box>
      {image?.localFile && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              left: 0,
              position: "absolute",
              height: "60px",
              width: "100%",
              order: 1,
              top: 0,
            }}
          >
            <BackgroundImage image={image} alt={image.alt || ""} />
          </Box>
        </Box>
      )}
    </Container>
  )
}

export default Footer

{
  /*
        

          {dealers &&
            dealers.length > 0 &&
            dealers.map((o, i) => (
              <Box px={4} key={i}>
                {o.name && (
                  <Box mt="15px">
                    <Heading
                      variant="text.footerHeading"
                      sx={{
                        display: ["block", "inline-block"],
                        color: "white",
                        pr: 2,
                      }}
                    >
                      {Parser(o.name)}
                    </Heading>

                    {o.phone && (
                      <ThemeLink
                        variant="text.footerText"
                        sx={{
                          textDecoration: "none",
                          color: "white",
                          "&:hover": { color: "notice" },
                        }}
                        href={`tel:${o.phone}`}
                      >
                        {o.phone}
                      </ThemeLink>
                    )}
                  </Box>
                )}
                {o.address && (
                  <Box mb="10px">
                    <Text variant="text.footerText" sx={{ color: "white" }}>
                      {o.address}
                    </Text>
                  </Box>
                )}
              </Box>
            ))}
       
       
         */
}
