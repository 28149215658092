import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/css"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import { Text, Flex } from "theme-ui"
import { navigate } from "gatsby"

// import app components
import useMenuItems from "../../hooks/useMenuItems"
import ChevronDown from "../../icons/chevron-down.svg"
import { useStore } from "../../store"
import { formatLink } from "../../utils"
import theme from "../../theme"

interface Props {
  path?: string
}

const MobileMenu: React.FC<Props> = (props: Props) => {
  const { path } = props

  const items = useMenuItems({ slug: "menu" })

  const [
    {
      appState: { menu },
    },
    dispatch,
  ] = useStore()

  const itemIds = items ? items.map(({ key }) => key) : [] // set all items open by default

  const [activeItems, setActiveItems] = useState([...itemIds])

  const handleArrowClick = (id: any, url: any) => {
    let newItems = [...activeItems]

    if (activeItems.includes(id)) {
      newItems = newItems.filter((i) => i !== id)
    } else {
      newItems.push(id)
    }

    setActiveItems(newItems)
    navigate(formatLink(url))
  }

  const handleCloseMenu = () => dispatch({ type: "SET_MENU", payload: false })

  return (
    <>
      <Menu {...props} menuState={menu}>
        {items &&
          items.map(({ id, url, label, childItems }, index) => {
            return (
              <MenuItem key={index}>
                {childItems?.nodes?.length ? (
                  <>
                    {url === "#" ? (
                      <MenuLinkContainer
                        onClick={() => handleArrowClick(id, url)}
                      >
                        <Text
                          variant="mobileNav"
                          sx={{
                            color:
                              path?.replace(/^[\\/]+|[\\/]+$/g, "") ===
                              url?.replace(/^[\\/]+|[\\/]+$/g, "")
                                ? "primary"
                                : "white",
                          }}
                        >
                          {label && Parser(label)}
                        </Text>

                        <Chevron
                          active={activeItems.includes(id)}
                          path={path}
                          url={url}
                        />
                      </MenuLinkContainer>
                    ) : (
                      <MenuLinkContainer
                        onClick={() => handleArrowClick(id, url)}
                      >
                        <MenuLink activeStyle={{ color: "primary" }}>
                          <Text
                            variant="mobileNav"
                            sx={{
                              color:
                                path?.replace(/^[\\/]+|[\\/]+$/g, "") ===
                                url?.replace(/^[\\/]+|[\\/]+$/g, "")
                                  ? "primary"
                                  : "white",
                            }}
                          >
                            {label && Parser(label)}
                          </Text>
                        </MenuLink>

                        <Chevron
                          active={activeItems.includes(id)}
                          path={path}
                          url={url}
                        />
                      </MenuLinkContainer>
                    )}

                    <SubMenu
                      className={`sub-menu`}
                      active={activeItems.includes(id)}
                    >
                      {childItems?.nodes?.map((o, i) => {
                        return (
                          <SubMenuLink
                            key={i}
                            to={formatLink(o.url)}
                            activeClassName="active"
                            onClick={handleCloseMenu}
                            target="_blank"
                          >
                            <Text
                              sx={{
                                color:
                                  path?.replace(/^[\\/]+|[\\/]+$/g, "") ===
                                  o.url?.replace(/^[\\/]+|[\\/]+$/g, "")
                                    ? "primary"
                                    : "white",
                                "&:hover": { color: "primary" },
                              }}
                              variant="mobileNav"
                            >
                              {o?.label && Parser(o.label)}
                            </Text>
                          </SubMenuLink>
                        )
                      })}
                    </SubMenu>
                  </>
                ) : (
                  <>
                    <MenuLink to={formatLink(url)} onClick={handleCloseMenu}>
                      <Text
                        sx={{
                          color:
                            path?.replace(/^[\\/]+|[\\/]+$/g, "") ===
                            url?.replace(/^[\\/]+|[\\/]+$/g, "")
                              ? "primary"
                              : "white",
                          "&:hover": { color: "primary" },
                        }}
                        color="white"
                        variant="mobileNav"
                      >
                        {label && Parser(label)}
                      </Text>
                    </MenuLink>
                  </>
                )}
              </MenuItem>
            )
          })}
      </Menu>

      <Gradient
        onClick={() => dispatch({ type: "SET_MENU", payload: false })}
        menuState={menu}
      />
    </>
  )
}

const Chevron = (props) => {
  const { active, onClick, path, url } = props

  return (
    <Flex
      onClick={onClick}
      sx={{
        alignItems: "center",
        padding: "0 12px",
        cursor: "pointer",

        ".chevron-icon": {
          color:
            path?.replace(/^[\\/]+|[\\/]+$/g, "") ===
            url?.replace(/^[\\/]+|[\\/]+$/g, "")
              ? "primary"
              : "white",
          transition: "transform 150ms ease-in-out",
          transform: active ? "rotate(-180deg)" : "rotate(0)",
        },
      }}
    >
      <ChevronDown className="chevron-icon" />
    </Flex>
  )
}

const Menu = styled.div`
  ${(props: any) =>
    props.menuState
      ? `
          transform: translateX(0);
          opacity: 1;
        `
      : `
          transform: translateX(120%);
          opacity: 0;
        `};

  position: fixed;
  @media (max-width: 40em) {
    top: 90px;
  }
  top: 100px;
  width: 100%;
  right: 0;
  z-index: -2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background: ${theme.colors.lightBlue};
  padding-top: 32px;
  padding-bottom: 32px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  @media (min-width: 1300px) {
    display: none;
  }
`

const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;
`

const item = css`
  position: relative;
  color: #fff;
  text-decoration: none;
  width: 100%;
`

const MenuLinkContainer = styled.div`
  display: flex;
  cursor: pointer;
  ${item};
  color: #fff;
  margin-bottom: 10px;
  &:hover {
    svg,
    span {
      color: ${theme.colors.primary};
    }
  }
`

const MenuItem = styled.div`
  padding: 0 30px;
  margin-bottom: 40px;
  ${item};
  color: #fff;
  &:first-child {
    padding-top: 30px;
  }
`

const MenuLink = styled(Link)`
  ${item};
  color: #fff;
  &:hover {
    color: ${theme.colors.primary};
  }
`

const SubMenu = styled.div`
  display: ${(props: any) => (props.active ? "block" : "none")};
`

const SubMenuLink = styled(Link)`
  display: block;
  padding: 12px;
  &:hover {
    color: ${theme.colors.primary};
  }
`

const Gradient = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 80px;
  z-index: -3;
  /* background: rgba(0, 0, 0, 0.5); */
  opacity: ${(props: any) => (!!props.menuState ? 1 : 0)};
  pointer-events: ${(props: any) => (!!props.menuState ? "all" : "none")};
  transition: ease all 0.2s;
`

export default MobileMenu
